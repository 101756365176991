import React, { Component } from "react";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import { withRouter } from "../../../components/Reusable/WithRouter";
import config from "../../../config/emrok.config";
import ProductService from "../../../services/ProductService";
import ProductAction from "../../../redux/actions/Product.action";
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import "../../../assets/css/admin/style.css";
import "../../../assets/css/admin/responsive.css";

class ProductEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      selectedFile: null,
      fileUploadError: '',
      imagename: '',
      isLoading: false,
      color: 'red',
      image: null
    }
    this.copyState = this.state
    this.fileInputRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      product: props.editable_product || null
    }
  }

  componentDidMount = () => {
    console.log(this.props, 98898888)
  }

  // componentWillUnmount = () => {
  //   this.props.setEditableProduct(null)
  // }

  handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log(this.state.product, 6666666, file);
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        console.log(reader, 7777)
        this.setState({ image: reader.result })
      }
      reader.readAsDataURL(file)
      let newFile = await new File([file], this.state.product._id + '.png', { type: "image/png" })
      // let newFile = await new File([file], this.state.product._id + '.' + file.name.split('.')[file.name.split('.').length - 1], { type: file.type })
      this.setState({ selectedFile: newFile, fileUploadError: '', imagename: '' }, () => {
        this.handleUpload()
      });
    }
  };

  handleUpload = () => {
    console.log(this.state.isLoading, "loading status", this.state.selectedFile);
    // Handle the file upload logic here
    const { selectedFile } = this.state;

    if (selectedFile) {
      this.setState({ isLoading: true });
      const fileType = selectedFile.type;
      const fileSizeMB = selectedFile.size / (1024 * 1024);
      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {

        this.setState({ isLoading: false });
        this.setState({ fileUploadError: 'Only JPG and PNG files are allowed.', color: "red" });

        return;
      }
      else if (fileSizeMB > 25) {
        this.setState({ isLoading: false });
        this.setState({ fileUploadError: 'File size must be less than 25MB.', color: "red" });

        return;
      }
    } else {
      this.setState({ fileUploadError: 'Please select a file first.' });
    }
  }

  submit = (e) => {
    e.preventDefault()
    if (!this.state.selectedFile) {
      this.setState({ fileUploadError: 'Please select a file first.' });
    }
    else {
      let payload = new FormData()
      payload.append("material", this.state.product._id || null)
      payload.append("product", this.state.selectedFile)

      //console.log('File to upload:', selectedFile, this.props.CustomerReducer.customer, payload);
      ProductService.changeImage(payload).then(async data => {
        console.log(data.data.data.filepath, "data !!!!!!!!!!!!file pathh");
        // await ProductService.getAllProductsByMaterial(null)
        this.setState({ isLoading: false });
        this.setState({ fileUploadError: 'Image uploaded', color: "green", imagename: data.data.data.filepath });
        this.props.navigate('/admin/product-managment/list')

      }).catch(err => {
        this.setState({ isLoading: false });
        console.log(err, "err")
      })
    }
  }

  render = () => {
    const { product, color, fileUploadError, imagename, isLoading, selectedFile, image } = this.state;

    return (
      <>
        <div className="layout-wrapper">
          <AdminHeader />
          <AdminSidebar />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row g-4">
                  <div className="col-md-5">
                    <h5 className="mb-20">Upload Image</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="btn-home flotright">
                      <a href="javascript:void(0);" onClick={() => this.props.navigate('/admin/product-managment/list')}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                        Back
                      </a>
                    </div>
                  </div>
                    <form className="mt-3 registration-form">
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <label className="form-label mb-2 lvl-clr-hd">
                            Name <span className="requiredFld"> * </span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control name-pt-ft rounded-4"
                            value={product ? product.name : ""}
                            readOnly={true}
                          />
                        </div>
                        <div className="col-md-8 mb-3">
                          <div className="d-flex flex-column">
                            <label className="form-label lvl-clr-hd mb-2">
                              Upload Image <span className="requiredFld"> * </span>
                            </label>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="me-4">
                                  <input
                                    type="file"
                                    ref={this.fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={this.handleFileChange}
                                  />
                                  <button type="button" className="btn btn-white form-control upload-btn" onClick={() => {
                                    console.log(this.fileInputRef);
                                    this.fileInputRef.current.click()
                                  }}>
                                    Please Upload Image
                                  </button>
                                  <p className="text-center" style={{ fontSize: 11, paddingTop: 10, textAlign: 'center', color: "#0a4f73", marginRight: 5 }}> Image needs to be uploaded in either jpeg or png format within a maximum limit of 25 MB </p>
                                  {fileUploadError && <div style={{ color, textAlign: 'center', marginRight: 0 }}>{fileUploadError}</div>}
                                  {this.state.imagename != "" && <p>Selected file: {this.state.imagename}</p>}
                                </div>
                              </div>
                              <div className="col-md-4 col-upload-image">
                                <div className="upload-image-box">
                                  <img
                                    className="prescriptionimage"
                                    src={image || `${config.serviceUrl}/images/${product.poduct_img !== "" ? product.poduct_img : "product_img/no-image.png"}?time=${(new Date()).getTime()}`}
                                  ></img>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-5">
                            {
                              image &&
                              <button type="button" className="btn btn-white form-control upload-btn clear-sec-btn me-2" onClick={() => {
                                this.setState({ image: null })
                              }}>
                                clear selection
                              </button>
                            }
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-4">
                          <div className="end-bck-btn-pt">
                            <div>
                              <button type="button" className="next-btn-crcl" onClick={this.submit}>
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                            {
                              image &&
                              <button type="button" className="btn btn-white form-control upload-btn clear-sec-btn me-2" onClick={() => {
                                this.setState({ image: null })
                              }}>
                                Clear Selection
                              </button>
                            }
                        </div>
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEditableProduct: (product) => dispatch(ProductAction.setEditableProduct(product))
  };
};

const mapStateToProps = ({ ProductReducer }) => {
  const { editable_product } = ProductReducer
  return {
    editable_product
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Common(ProductEdit)));
