import {
  SET_PRODUCTS,
  CLEAR_PRODUCT_STATE,
  SET_ALL_PRODUCTS_BY_MATEERIAL,
  SET_EDITABLE_PRODUCT
} from '../types/Product.type'

const initState = {
  products: [],
  common_products: [],
  editable_product: null
}

export default (state = initState, action) => {
  const { type, payload } = action
  console.log(action);
  switch (type) {
    case SET_PRODUCTS:
      return Object.assign({}, state, { products: payload })
    case SET_ALL_PRODUCTS_BY_MATEERIAL:
      return Object.assign({}, state, { common_products: payload })
    case SET_EDITABLE_PRODUCT:
      return Object.assign({}, state, { editable_product: payload })
    case CLEAR_PRODUCT_STATE:
      return Object.assign({}, initState)
    default:
      return state
  }
}