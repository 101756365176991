import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import OrderService from '../../../services/Order.service';
import CustomerHeader from "../../../components/layout/CustomerHeader";
import OrderListAction from "../../../redux/actions/OrderList.action";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import OrderAction from "../../../redux/actions/Order.action";
import OtherService from "../../../services/OtherService";
import moment from "moment";
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import DatePicker from 'react-datepicker';
import CommonAction from "../../../redux/actions/Common.action";
import DropdownDoctor from "../../../components/SearchableDropdown/DropdownDoctor.component";
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import Summary from "../../../components/Summary/Summary.component";
import { ExportToExcel } from '../../../components/Excel/ExportToExcel'

class NSMDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mrdetails: null,
            nsmStateDetails: null,
            mrid: "",
            mrDoctorList: [],
            mrinfo: "",
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            summaryCols: ["No. of Total Dr. Prescribed", "Total Rx Generated", "Total Rx Fulfilled", "Total Rx Under Process"],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "NSM_Data",
            nsmList: [],
            zmList: [],
            rmList: [],
            mrList: [],
            productList: [],
            selectedNSM: "",
            selectedZM: "",
            selectedRM: "",
            selectedMR: "",
            selectedProduct: ""
        }
        this.copyState = this.state
    }

    static getDerivedStateFromProps(props, state) {
        return {
            mrdetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
            nsmStateDetails: props.nsmStateDetails || null
        }
    }

    getSummary = async (mr_id = null) => {
        if (mr_id) {
            // let payload = {
            //     type: "NSM",
            //     id: mr_id
            // }

            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus, selectedNSM, selectedZM, selectedRM, selectedMR, selectedProduct } = this.state.nsmStateDetails;

            const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
            const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
            let payload = {
                type: "NSM",
                id: mr_id,
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: selectedState,
                town: selectedTown,
                doctor_id: selectedDoctor,
                orderStatus: setorderStatus,
                nsm_id: selectedNSM,
                zm_id: selectedZM,
                rm_id: selectedRM,
                mrsearch_id: selectedMR,
                product_id: selectedProduct,

            }
            OtherService.getSummary(payload).then(data => {
                console.log(data, "getSummary");
            }).catch(err => {
                console.log(err, "getSummary err");
            })
        }
    }

    clickFn = async (index) => {
        const mrdetails = this.props.mrdetails;
        if (mrdetails) {
            // let payload = await {
            //     mr_id: mrdetails._id,
            //     type: "NSM"
            // }
            let payload = {};
            let orderstatus = "";

            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus, selectedNSM, selectedZM, selectedRM, selectedMR, selectedProduct } = this.state.nsmStateDetails;

            const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
            const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
            payload = {
                mr_id: mrdetails._id,
                type: "NSM",
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: selectedState,
                town: selectedTown,
                doctor_id: selectedDoctor,
                orderStatus: setorderStatus,
                nsm_id: selectedNSM,
                zm_id: selectedZM,
                rm_id: selectedRM,
                mrsearch_id: selectedMR,
                product_id: selectedProduct,

            }


            if (index === 0 || index === 1) {
                payload = {
                    mr_id: mrdetails._id,
                    type: "NSM",
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    doctor_id: selectedDoctor,
                    orderStatus: setorderStatus,
                    nsm_id: selectedNSM,
                    zm_id: selectedZM,
                    rm_id: selectedRM,
                    mrsearch_id: selectedMR,
                    product_id: selectedProduct,

                }

                orderstatus = ""
                payload["orderStatus"] = ""
            }
            // else if (index === 2) {
            //     payload["orderStatus"] = await "cancelled"
            //     this.setState({ setorderStatus: "cancelled" })
            // }
            else if (index === 2) {
                payload["orderStatus"] = await "complete"
                orderstatus = "complete";
                this.setState({ setorderStatus: "complete" })
            }
            else if (index === 3) {
                payload["orderStatus"] = await "pending"
                orderstatus = "pending"
                this.setState({ setorderStatus: "pending" })
            }
            const nsmStateDetails = this.state.nsmStateDetails;
            nsmStateDetails.setorderStatus = orderstatus;
            nsmStateDetails.mrDoctorList = [];
            this.props.setNSMStateDetails(nsmStateDetails);
            this.getmrDetails(payload)

        }

    }

    formatData(auditdata) {

        console.log(auditdata, auditdata.length, "auditdata")

        let formatedArr = [];

        for (let i = 0; i < auditdata.length; i++) {

            const materials = auditdata[i].products.map(product => product.name);
            // Create a comma-separated string from the "material" values
            const materialString = materials.join(', ');

            formatedArr.push({

                "Order No": auditdata[i].order_id,
                "Doctor Name": auditdata[i].doctor.name,
                "Stockist name": auditdata[i].stockist.name,
                "Patient State": auditdata[i].customer.address.state,
                "Patient City": auditdata[i].customer.address.town,
                "Order Date": auditdata[i].createdAt ? moment(auditdata[i].createdAt).format('DD-MM-YYYY') : "",
                "Shipment Date": auditdata[i].GRN_DATE ? moment(auditdata[i].GRN_DATE).format('DD-MM-YYYY') : "",
                "Delivery Date": auditdata[i].deliveryDate ? moment(auditdata[i].deliveryDate).format('DD-MM-YYYY') : "",
                "Order Value": auditdata[i].total,
                "Product": materialString,
                "Status": auditdata[i].orderStatus,

            })
        }

        console.log(auditdata, auditdata.length, "auditdata")
        return formatedArr;


    }

    getmrDetails = (details) => {
        this.setState({ mrDoctorList: [] }, () => {
            MrService.getuserDetails(details).then(data => {
                console.log(data.data.data.mrdetails, "mr details");
                if (data.data.success) {
                    console.log(data.data.data.mrdetails, "data.data.data.mrdetails")
                    // if(!this.state.isSearch){
                    this.setState({ mrDoctorList: data.data.data.mrdetails });
                    const formatedData = this.formatData(data.data.data.mrdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "NSM_Data" + currentDateTime;
                    this.setState({ filename: filename });

                    const nsmStateDetails = this.state.nsmStateDetails;
                    nsmStateDetails.formatedData = formatedData;

                    if (nsmStateDetails.mrDoctorList.length) {
                        nsmStateDetails.mrDoctorList = nsmStateDetails.mrDoctorList;
                    } else {
                        nsmStateDetails.mrDoctorList = data.data.data.mrdetails;
                    }

                    nsmStateDetails.filename = filename;
                    this.props.setNSMStateDetails(nsmStateDetails);
                    // }
                }



            }).catch(err => {

                console.log(err, "err")
            })
        });
    }

    componentDidMount() {

        const mrdetails = this.props.mrdetails;
        if (mrdetails) {
            const mr_id = mrdetails._id;
            let details = {
                mr_id: mr_id,
                type: "NSM"
            };
            console.log(details, "mr details",);
            this.getSummary(mr_id)
            this.getmrDetails(details)


            MrService.getuserInfo(details).then(data => {
                console.log(data.data, "mr info");

                if (data.data.success && data.data.data.mrinfo !== '') {
                    console.log(data.data.data.mrinfo[0], "okkkkk");

                    this.setState({ mrinfo: data.data.data.mrinfo[0] });

                    const nsmStateDetails = this.state.nsmStateDetails;
                    nsmStateDetails.mrinfo = data.data.data.mrinfo[0];
                    this.props.setNSMStateDetails(nsmStateDetails);
                }
                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            let detailsBody = {
                id: mr_id,
                type: "nsm",
            };
            MrService.getDoctorsByMr(detailsBody).then(data => {
                console.log(data.data, "doctordetailsbymr123");

                if (data.data.success && data.data.data.doctorinfo !== '') {
                    console.log(data.data, "doctorokkk1233");
                    this.getDoctors(data.data.data.doctorinfo);

                }

                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);


            let detailspayload = {
                id: mr_id,
                type: "nsm",
            };
            MrService.getusers(detailspayload).then(data => {
                console.log(data.data.data.response, "detailspayload");

                if (data.data.success) {
                    const rm = data.data.data.response.map(item => item.rm)
                    const uniqueObjectsRM = [...new Map(rm.map(obj => [obj._id, obj])).values()];

                    console.log(uniqueObjectsRM, "uniqueObjectsRM")

                    this.formatAndSet(uniqueObjectsRM, "RM")

                    const zm = data.data.data.response.map(item => item.zm)
                    const uniqueObjectsZM = [...new Map(zm.map(obj => [obj._id, obj])).values()];

                    this.formatAndSet(uniqueObjectsZM, "ZM")


                    const mr = data.data.data.response.map(item => item.mr)
                    const uniqueObjectsMR = [...new Map(mr.map(obj => [obj._id, obj])).values()];

                    this.formatAndSet(uniqueObjectsMR, "MR")
                }
            }).catch(err => {

                console.log(err, "err")
            })

            MrService.getProductList().then(data => {

                if (data.data.success && data.data.data.length > 0) {

                    this.formatAndSet(data.data.data, "PRODUCT")
                }

                console.log(data, "all products")

            })
        } else {
        }
    }

    formatAndSet = (data, type) => {

        let finalData = [];
        for (let index = 0; index < data.length; index++) {
            finalData[index] = {
                "name": data[index].name,
                "label": data[index].name,
                "value": data[index]._id,
                "id": data[index]._id,
            };


        }


        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };

        let optionsWithAll = [allOption, ...finalData];


        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index
        const nsmStateDetails = this.state.nsmStateDetails;
        if (type == "NSM") {

            this.setState({ nsmList: optionsWithAll });
            nsmStateDetails.nsmList = optionsWithAll;

        } else if (type == "ZM") {
            this.setState({ zmList: optionsWithAll });
            nsmStateDetails.zmList = optionsWithAll;

        } else if (type == "RM") {

            this.setState({ rmList: optionsWithAll });
            nsmStateDetails.rmList = optionsWithAll;

        } else if (type == "MR") {
            this.setState({ mrList: optionsWithAll });
            nsmStateDetails.mrList = optionsWithAll;
        } else if (type == "PRODUCT") {
            this.setState({ productList: optionsWithAll });
            nsmStateDetails.productList = optionsWithAll;

        }
        this.props.setNSMStateDetails(nsmStateDetails);
    }
    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/nsm/track-info-order');
                    this.props.setUserType('nsm');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }
    componentWillUnmount() {
        // if (this.dataTable) {
        //     this.dataTable.destroy(true);
        // }
    }
    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {

            const materials = data[i].products.map(product => product.name);
            // Create a comma-separated string from the "material" values
            const materialString = materials.join(', ');
            resp.push({
                _id: data[i]._id,
                stockist_name: data[i].stockist.name,
                doctor_name: data[i].doctor.name,
                customer_name: data[i].customer.name,
                customer_state: data[i].customer.address.state,
                customer_town: data[i].customer.address.town,
                customer_mobile: data[i].customer.mobileno,
                order_date: data[i].createdAt ? data[i].createdAt : "",
                grn_date: data[i].GRN_DATE ? data[i].GRN_DATE : "",
                order_status: data[i].orderStatus ? data[i].orderStatus : "",
                order_id: data[i].order_id ? data[i].order_id : "",
                total: data[i].total ? data[i].total : "",
                product_code: materialString,
                delivery_date: data[i].deliveryDate ? data[i].deliveryDate : ""

            })
        }
        return resp
    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    const nsmStateDetails = this.state.nsmStateDetails;
                    nsmStateDetails.states = data.states;
                    this.props.setNSMStateDetails(nsmStateDetails);

                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.nsmStateDetails.selectedState || null
                        console.log(_state, this.state.nsmStateDetails.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.nsmStateDetails.states.length; i++) {
                                if (this.state.nsmStateDetails.states[i].name === _state) {
                                    stateObj = this.state.nsmStateDetails.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })

                    setTimeout(() => {
                        let stateDetails = this.state.nsmStateDetails.states;
                        const findIfExistAll = stateDetails.find(item => item.name === 'All');
                        console.log(findIfExistAll, 33333);

                        if (!findIfExistAll) {

                            const allOption = {
                                "name": "All",
                                "isoCode": null,
                                "countryCode": null,
                                "latitude": null,
                                "longitude": null,
                                "label": "All",
                                "value": "all"
                            };
                            console.log(this.state.states, "details state")
                            // Create a new array with the "All" option at the beginning
                            let optionsWithAll = [allOption, ...stateDetails];
                            console.log(optionsWithAll, "details state all")
                            this.setState({ states: optionsWithAll })

                            const nsmStateDetails = this.state.nsmStateDetails;
                            nsmStateDetails.states = optionsWithAll;
                            this.props.setNSMStateDetails(nsmStateDetails);
                        }
                    }, 500);
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {

                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.nsmStateDetails.towns;
                        const allOption1 = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll1 = [allOption1, ...stateDetails];
                        console.log(optionsWithAll1, "details state all")
                        this.setState({ towns: optionsWithAll1 })
                        const nsmStateDetails = this.state.nsmStateDetails;
                        nsmStateDetails.towns = optionsWithAll1;
                        this.props.setNSMStateDetails(nsmStateDetails);

                    }, 500);
                }
                // }, 2000);
            })
        }
    }


    getDoctors = (doctors) => {
        // Create an array to store the grouped data
        const groupedData = [];
        const finalData = [];

        // Create an object to keep track of seen doctor._id values
        const seenDoctorIds = {};

        // Use forEach to iterate through the data
        doctors.forEach((currentValue) => {
            const doctorId = currentValue._id;

            // Check if we've already seen this doctor._id
            if (!seenDoctorIds[doctorId]) {
                // Add the current value to the groupedData array
                groupedData.push(currentValue);

                // Mark the doctor._id as seen
                seenDoctorIds[doctorId] = true;
            }
        });

        for (let index = 0; index < groupedData.length; index++) {
            finalData[index] = {
                "name": groupedData[index].name,
                "label": groupedData[index].name,
                "value": groupedData[index]._id,
                "id": groupedData[index]._id,
            };


        }


        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };

        let optionsWithAll = [allOption, ...finalData];


        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index

        this.setState({ doctorList: optionsWithAll });

        const nsmStateDetails = this.state.nsmStateDetails;
        if (nsmStateDetails.doctorList.length == 0) {
            nsmStateDetails.doctorList = optionsWithAll;
            this.props.setNSMStateDetails(nsmStateDetails);
        }

        console.log(groupedData, "groupedData")

        // Now 'groupedData' contains the data grouped by doctor._id


    }

    changeValFromDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });

        const nsmStateDetails = this.state.nsmStateDetails;
        nsmStateDetails.fromdate = date;
        this.props.setNSMStateDetails(nsmStateDetails);

    }
    changeValToDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
        const nsmStateDetails = this.state.nsmStateDetails;
        nsmStateDetails.todate = date;
        this.props.setNSMStateDetails(nsmStateDetails);


    }

    searchFilter = (event) => {
        this.setState({ mrDoctorList: [] })

        const nsmStateDetails = this.state.nsmStateDetails;
        nsmStateDetails.mrDoctorList = [];
        this.props.setNSMStateDetails(nsmStateDetails);

        const mrdetails = this.props.mrdetails;
        const mr_id = mrdetails._id;
        const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus, selectedNSM, selectedZM, selectedRM, selectedMR, selectedProduct } = this.state;

        const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
        const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";

        let details = {
            mr_id: mr_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            doctor_id: selectedDoctor,
            orderStatus: setorderStatus,
            nsm_id: selectedNSM,
            zm_id: selectedZM,
            rm_id: selectedRM,
            mrsearch_id: selectedMR,
            product_id: selectedProduct,
            type: "NSM"
        };

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            console.log(details, "details 1234455")
            MrService.getuserDetails(details).then(data => {
                console.log(data.data, "mr details after filter");

                if (data.data.success && data.data.data.mrdetails !== '') {
                    console.log(data.data, "data after filter");
                    // setTimeout(() => {
                    //this.setOrderHistoryData(data.data.data.mrdetails)
                    this.setState({ mrDoctorList: data.data.data.mrdetails });
                    this.setState({ isSearch: true })
                    const formatedData = this.formatData(data.data.data.mrdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "NSM_Data" + currentDateTime;
                    this.setState({ filename: filename });
                    // this.populateStates(countryObj.isoCode)	
                    // }, 500);

                    const nsmStateDetails = this.state.nsmStateDetails;
                    nsmStateDetails.mrDoctorList = data.data.data.mrdetails;
                    nsmStateDetails.isSearch = true;
                    nsmStateDetails.exportData = formatedData;
                    nsmStateDetails.filename = filename;
                    this.props.setNSMStateDetails(nsmStateDetails);

                    this.getSummary(mr_id)
                } else {
                    this.getSummary(mr_id)
                }

            }).catch(err => {

                console.log(err, "err")
            })
        }

        console.log("search filter");
    }

    changeDoctor = (event) => {
        const doctorId = event.target.value;
        this.setState({ selectedDoctor: doctorId });
        const nsmStateDetails = this.state.nsmStateDetails;
        nsmStateDetails.selectedDoctor = doctorId;
        this.props.setNSMStateDetails(nsmStateDetails);
        console.log(doctorId, "doctorId");
    }

    clearFilter = () => {

        this.setState({
            mrdetails: null,
            nsmStateDetails: null,
            mrid: "",
            mrDoctorList: [],
            mrinfo: "",
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "NSM_Data",
            nsmList: [],
            zmList: [],
            rmList: [],
            mrList: [],
            productList: [],
            selectedNSM: "",
            selectedZM: "",
            selectedRM: "",
            selectedMR: "",
            selectedProduct: ""
        })


        let details = null;
        const mrdetails = this.props.mrdetails;
        if (mrdetails) {
            const mr_id = mrdetails._id;
            details = {
                mr_id: mr_id,
                type: "NSM"
            };
        }
        const storeSearchData = this.state.nsmStateDetails;

        storeSearchData.mrdetails= null
        storeSearchData.nsmStateDetails= null
        storeSearchData.mrid= ""
        storeSearchData.mrDoctorList= []
        storeSearchData.mrinfo= ""
        storeSearchData.towns= []
        storeSearchData.showTowns= false
        storeSearchData.selectedState= ""
        storeSearchData.selectedTown= ""
        storeSearchData.selectedDoctor= ""
        storeSearchData.fromdate= ""
        storeSearchData.todate= ""
        storeSearchData.currentDate= this.getCurrentDate()
        storeSearchData.doctorList= []
        storeSearchData.isSearch= false
        storeSearchData.summaryDetails= null
        storeSearchData.setorderStatus= ""
        storeSearchData.exportData= null
        storeSearchData.fileName= "NSM_Data"
        storeSearchData.nsmList= []
        storeSearchData.zmList= []
        storeSearchData.rmList= []
        storeSearchData.mrList= []
        storeSearchData.productList= []
        storeSearchData.selectedNSM= ""
        storeSearchData.selectedZM= ""
        storeSearchData.selectedRM= ""
        storeSearchData.selectedMR= ""
        storeSearchData.selectedProduct= ""
        this.props.setNSMStateDetails(storeSearchData);

        this.getmrDetails(details)

        this.getSummary(details.mr_id)
    }



    render = () => {

        let _data = this.setOrderHistoryData(this.state.nsmStateDetails.mrDoctorList)
        console.log(_data, "_data")
        const { exportData, fileName, countries, states, search, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, selectedDoctor, doctorList, nsmList, zmList, rmList, mrList, selectedNSM, selectedZM, selectedRM, selectedMR, productList, selectedProduct } = this.state.nsmStateDetails;

        const { summaryCols, summaryDetails } = this.state
        return (

            <>

                <section className="landing-page-pt">
                    <div className="container">
                        <MrHeader />
                        <div className="mr-landing-page">
                            <div className="landing-head-pt">
                                {/* <h2>MR Landing page</h2> */}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="landing-profl-detls pt-5">

                    <div className="container">

                        <div className="landing-form-dtls">
                            {/* <h5 className="title">Welcome {this.state.mrinfo.name} to Emrok Application (NSM)</h5> */}
                            <div className="row mb-25">
                                <div className="col-md-6">
                                    <h5 className="title">Welcome {this.state.nsmStateDetails.mrinfo.name} to Emrok Application (NSM)</h5>
                                </div>


                                <div className='col-md-6'>

                                    <div className="flotright marginright">
                                        <ExportToExcel apiData={exportData} fileName={fileName} />
                                    </div>
                                </div>


                            </div>
                            <div className="row mt-30 mb-20 ">
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order From Date</label>
                                    {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={fromdate}
                                        onChange={(date) => this.changeValFromDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order To Date</label>
                                    {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={todate}
                                        onChange={(date) => this.changeValToDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                        minDate={fromdate}
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // let _registration = this.state.registration
                                                        // _registration.address["state"] = item.name
                                                        // _registration.address["town"] = ""
                                                        this.setState({ selectedState: item.name })
                                                        const nsmStateDetails = this.state.nsmStateDetails;
                                                        nsmStateDetails.selectedState = item.name ;
                                                        this.props.setNSMStateDetails(nsmStateDetails);

                                                        //console.log(item, "item");
                                                        if (item.name != "All") {
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })

                                                            const nsmStateDetails = this.state.nsmStateDetails;
                                                            nsmStateDetails.towns = [];
                                                            nsmStateDetails.selectedTown = "";
                                                            this.props.setNSMStateDetails(nsmStateDetails);
    
                                                        }
                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }
                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedTown: item.name })
                                                        const nsmStateDetails = this.state.nsmStateDetails;
                                                        nsmStateDetails.selectedTown = item.name;
                                                        this.props.setNSMStateDetails(nsmStateDetails);
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }
                                    {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Doctors </label>
                                    {/* <select name="doctorname" className="form-control name-pt-ft" onChange={(event) => this.changeDoctor(event)} value={selectedDoctor}>
                                        <option value="">Please Select</option>
                                        {doctorList.length > 0 ? doctorList.map(doctor => (
                                            <option value={doctor._id}>{doctor.name}</option>

                                        )) : <option></option>}

                                    </select> */}

                                    {
                                        doctorList.length ?
                                            <DropdownDoctor
                                                items={doctorList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedDoctor: item.id })
                                                        const nsmStateDetails = this.state.nsmStateDetails;
                                                        nsmStateDetails.selectedDoctor =  item.id;
                                                        this.props.setNSMStateDetails(nsmStateDetails);
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedDoctor}
                                            /> :
                                            <DropdownDoctor
                                                items={doctorList}
                                                value={selectedDoctor}
                                            />
                                    }

                                </div>

                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">ZM </label>
                                    {
                                        zmList.length ?
                                            <DropdownDoctor
                                                items={zmList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedZM: item.id })
                                                        const nsmStateDetails = this.state.nsmStateDetails;
                                                        nsmStateDetails.selectedZM =  item.id;
                                                        this.props.setNSMStateDetails(nsmStateDetails);
                                                        //console.log(item, "item")
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedZM}
                                            /> :
                                            <DropdownDoctor
                                                items={zmList}
                                                value={selectedZM}
                                            />
                                    }

                                </div>

                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">RM </label>
                                    {
                                        rmList.length ?
                                            <DropdownDoctor
                                                items={rmList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedRM: item.id })
                                                        const nsmStateDetails = this.state.nsmStateDetails;
                                                        nsmStateDetails.selectedRM =  item.id;
                                                        this.props.setNSMStateDetails(nsmStateDetails);
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedRM}
                                            /> :
                                            <DropdownDoctor
                                                items={rmList}
                                                value={selectedRM}
                                            />
                                    }

                                </div>

                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">MR </label>
                                    {
                                        mrList.length ?
                                            <DropdownDoctor
                                                items={mrList}
                                                onSelect={(item) => {
                                                    if (item) {

                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedMR: item.id })
                                                        const nsmStateDetails = this.state.nsmStateDetails;
                                                        nsmStateDetails.selectedMR =  item.id;
                                                        this.props.setNSMStateDetails(nsmStateDetails);
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedMR}
                                            /> :
                                            <DropdownDoctor
                                                items={mrList}
                                                value={selectedMR}
                                            />
                                    }

                                </div>

                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Product Name </label>
                                    {
                                        productList.length ?
                                            <DropdownDoctor
                                                items={productList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.id);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedProduct: item.name })

                                                        const nsmStateDetails = this.state.nsmStateDetails;
                                                        nsmStateDetails.selectedProduct =  item.name;
                                                        this.props.setNSMStateDetails(nsmStateDetails);
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedProduct}
                                            /> :
                                            <DropdownDoctor
                                                items={productList}
                                                value={selectedProduct}
                                            />
                                    }

                                </div>


                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                    <button type="button" className="landing-clear-btn" onClick={(event) =>this.clearFilter()}>
                                        Clear Filter</button>
                                </div>

                            </div>
                            {/* Summary table start */}
                            <Summary
                                columns={summaryCols}
                                data={[{
                                    prescriptionCount: summaryDetails ? summaryDetails.prescriptionCount || 0 : 0,
                                    totalOrderCount: summaryDetails ? summaryDetails.totalOrderCount || 0 : 0,
                                    // cancelledOrderCount: summaryDetails ? summaryDetails.cancelledOrderCount || 0 : 0,
                                    completeOrderCount: summaryDetails ? summaryDetails.completeOrderCount || 0 : 0,
                                    pendingOrderCount: summaryDetails ? summaryDetails.pendingOrderCount || 0 : 0
                                }]}
                                clickFn={this.clickFn}
                            />

                            {/* Summary table end */}
                            <h5 className="mb-20">Details</h5>
                            <div className="table-responsive">

                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No'
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'Doctor Name',
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'Stockist Name',
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'Patient State',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Patient City',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Order Date',
                                                    // selector: row => row.total,

                                                },
                                                // {
                                                //     name: 'Shipment',
                                                //     // selector: row => row.total,

                                                // },
                                                {
                                                    name: 'Delivery Date',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Order Value',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Product',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Status',
                                                    // selector: row => row.orderStatus,

                                                }
                                            ]}
                                            data={this.setOrderHistoryData(this.state.nsmStateDetails.mrDoctorList)}
                                            handleTrackOrder={this.handleTrackOrder}
                                            pageName="mrlanding"

                                        /> : <p className="text-center">No Records Found</p>
                                }

                            </div>
                        </div>
                        <CustomerFooter />
                    </div>
                </section>


            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // dispatching plain actions
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        setNSMStateDetails: (nsmStateDetails) => dispatch(MrAction.setNSMStateDetails(nsmStateDetails))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, MrReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { mrdetails, nsmStateDetails } = MrReducer
    const { summaryDetails } = CommonReducer
    console.log(customer, 123);
    console.log(mrdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        mrdetails,
        summaryDetails,
        nsmStateDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(NSMDetails)))