import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import moment from "moment";
import AdminHeader from "../../components/layout/Admin/AdminHeader";
import AdminFooter from "../../components/layout/Admin/AdminFooter";
import AdminSidebar from "../../components/layout/Admin/AdminSidebar";
import AdminAction from "../../redux/actions/Admin.action";
import AdminService from "../../services/Admin.service";
import '../../assets/css/admin/style.css';
import '../../assets/css/admin/responsive.css';

import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            totalOrders:"",
            totalAmount:"",
            totalCustomerCount:""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            admin: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    componentDidMount() {
        try {
             AdminService.getCustomerCount().then(data => {
               
                console.log(data.data.data.data[0].totalCustomer, "10001");
               
                if (data.data.success) {
                    // console.log(data.data.data.data[0].totalCustomer, "10001");
                    this.setState({ totalCustomerCount:data.data.data.data[0].totalCustomer });
                }

            }).catch(err => {

                console.log(err, "err")
            })

            let res =  AdminService.getOrderCountDetails().then(data => {
                console.log(data.data.success, "1000");

                console.log(res, 123333)
                if (data.data.success) {
                    
                    this.setState({ totalAmount:data.data.data.data[0].totalAmount,totalOrders:data.data.data.data[0].totalOrders });
                }

            }).catch(err => {

                console.log(err, "err")
            })
        } catch (error) {

        }
    }
    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }
    navigateTo = (type) => {

        if (type == 'order_management') {

            this.props.setActiveMenuItem('ordermanagment');
            this.props.navigate('/admin/order-managment');
        }

        if(type=="callcenter"){
            this.props.setActiveMenuItem('user_managment');
            this.props.navigate('/admin/user-managment/callcenter');
        }

    }

    render = () => {
        const {totalAmount,totalOrders,totalCustomerCount } = this.state
        console.log(totalCustomerCount,2555);
        return (

            <>

                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="dashboardheading">

                                    <h4>Dashboard</h4>

                                </div>
                                <div className="row g-4">

                                    <div className="col-md-6">
                                        <div className="card card-animate">
                                        <a className="" href="javascript:void(0)" onClick={() => { this.props.navigate('/admin/order-managment') }}><div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="fw-medium text-muted mb-0">Total Sales</p>
                                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                                            <span className="counter-value" data-target="28.05" />{totalOrders}
                                                        </h2>
                                                        {/* <p className="mb-0 text-muted">
                                                            <span className="badge bg-light text-success mb-0">
                                                                <i className="ri-arrow-up-line align-middle" /> 16.24 %
                                                            </span>
                                                            vs. previous month
                                                        </p> */}
                                                    </div>
                                                    <div>
                                                        <div className="avatar-sm flex-shrink-0">
                                                            {/* <span className="avatar-title bg-light-red text-primary-red rounded-circle fs-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 512 512"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M432 32a63.973 63.973 0 0 0-34.344 117.963L355.894 296.13A64.372 64.372 0 0 0 352 296a63.659 63.659 0 0 0-38.193 12.678l-77.154-64.295A64 64 0 1 0 131.259 269.7l-45.292 90.588A64.334 64.334 0 0 0 80 360a64.082 64.082 0 1 0 36.243 11.29l42.8-85.589a63.845 63.845 0 0 0 59.982-14.356l74.7 62.252a64 64 0 1 0 92.621-27.56l41.76-146.167c1.289.078 2.585.13 3.894.13a64 64 0 0 0 0-128ZM80 456a32 32 0 1 1 32-32a32.036 32.036 0 0 1-32 32Zm96-200a32 32 0 1 1 32-32a32.036 32.036 0 0 1-32 32Zm176 136a32 32 0 1 1 32-32a32.036 32.036 0 0 1-32 32Zm80-264a32 32 0 1 1 32-32a32.036 32.036 0 0 1-32 32Z"
                                                                    />
                                                                </svg>
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </a>
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>
                                    {/* end col*/}
                                    <div className="col-md-6">
                                        <div className="card card-animate">
                                            <a className="" href="javascript:void(0)" onClick={() => { this.props.navigate('/admin/order-managment') }}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="fw-medium text-muted mb-0">Total Orders</p>
                                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                                            <span className="counter-value" data-target="97.66" />{totalOrders}
                                                        </h2>
                                                        {/* <p className="mb-0 text-muted">
                                                            <span className="badge bg-light text-danger mb-0">
                                                                <i className="ri-arrow-down-line align-middle" /> 3.96 %
                                                            </span>
                                                            vs. previous month
                                                        </p> */}
                                                    </div>
                                                    <div>
                                                        <div className="avatar-sm flex-shrink-0">
                                                            {/* <span className="avatar-title bg-light-red text-primary-red rounded-circle fs-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 2048 2048"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="m2029 1453l-557 558l-269-270l90-90l179 178l467-466l90 90zM1024 640H640V512h384v128zm0 256H640V768h384v128zm-384 128h384v128H640v-128zM512 640H384V512h128v128zm0 256H384V768h128v128zm-128 128h128v128H384v-128zm768-384V128H256v1792h896v128H128V0h1115l549 549v731l-128 128V640h-512zm128-128h293l-293-293v293z"
                                                                    />
                                                                </svg>
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </a>
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>
                                    {/* end col*/}
                                    <div className="col-md-6">
                                        <div className="card card-animate">
                                            <a className="" >
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="fw-medium text-muted mb-0">Total Customers</p>
                                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                                            <span className="counter-value" data-target="97.66" />{totalCustomerCount}
                                                        </h2>
                                                        {/* <p className="mb-0 text-muted">
                                                            <span className="badge bg-light text-danger mb-0">
                                                                <i className="ri-arrow-down-line align-middle" /> 3.96 %
                                                            </span>
                                                            vs. previous month
                                                        </p> */}
                                                    </div>
                                                    <div>
                                                        <div className="avatar-sm flex-shrink-0">
                                                            {/* <span className="avatar-title bg-light-red text-primary-red rounded-circle fs-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={32}
                                                                    height={32}
                                                                    viewBox="0 0 36 36"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M17.9 17.3c2.7 0 4.8-2.2 4.8-4.9s-2.2-4.8-4.9-4.8S13 9.8 13 12.4c0 2.7 2.2 4.9 4.9 4.9zm-.1-7.7c.1 0 .1 0 0 0c1.6 0 2.9 1.3 2.9 2.9s-1.3 2.8-2.9 2.8c-1.6 0-2.8-1.3-2.8-2.8c0-1.6 1.3-2.9 2.8-2.9z"
                                                                        className="clr-i-outline clr-i-outline-path-1"
                                                                    />
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M32.7 16.7c-1.9-1.7-4.4-2.6-7-2.5h-.8c-.2.8-.5 1.5-.9 2.1c.6-.1 1.1-.1 1.7-.1c1.9-.1 3.8.5 5.3 1.6V25h2v-8l-.3-.3z"
                                                                        className="clr-i-outline clr-i-outline-path-2"
                                                                    />
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M23.4 7.8c.5-1.2 1.9-1.8 3.2-1.3c1.2.5 1.8 1.9 1.3 3.2c-.4.9-1.3 1.5-2.2 1.5c-.2 0-.5 0-.7-.1c.1.5.1 1 .1 1.4v.6c.2 0 .4.1.6.1c2.5 0 4.5-2 4.5-4.4c0-2.5-2-4.5-4.4-4.5c-1.6 0-3 .8-3.8 2.2c.5.3 1 .7 1.4 1.3z"
                                                                        className="clr-i-outline clr-i-outline-path-3"
                                                                    />
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M12 16.4c-.4-.6-.7-1.3-.9-2.1h-.8c-2.6-.1-5.1.8-7 2.4L3 17v8h2v-7.2c1.6-1.1 3.4-1.7 5.3-1.6c.6 0 1.2.1 1.7.2z"
                                                                        className="clr-i-outline clr-i-outline-path-4"
                                                                    />
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M10.3 13.1c.2 0 .4 0 .6-.1v-.6c0-.5 0-1 .1-1.4c-.2.1-.5.1-.7.1c-1.3 0-2.4-1.1-2.4-2.4c0-1.3 1.1-2.4 2.4-2.4c1 0 1.9.6 2.3 1.5c.4-.5 1-1 1.5-1.4c-1.3-2.1-4-2.8-6.1-1.5c-2.1 1.3-2.8 4-1.5 6.1c.8 1.3 2.2 2.1 3.8 2.1z"
                                                                        className="clr-i-outline clr-i-outline-path-5"
                                                                    />
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="m26.1 22.7l-.2-.3c-2-2.2-4.8-3.5-7.8-3.4c-3-.1-5.9 1.2-7.9 3.4l-.2.3v7.6c0 .9.7 1.7 1.7 1.7h12.8c.9 0 1.7-.8 1.7-1.7v-7.6zm-2 7.3H12v-6.6c1.6-1.6 3.8-2.4 6.1-2.4c2.2-.1 4.4.8 6 2.4V30z"
                                                                        className="clr-i-outline clr-i-outline-path-6"
                                                                    />
                                                                    <path fill="none" d="M0 0h36v36H0z" />
                                                                </svg>
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </a>
                                           
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>
                                    {/* end col*/}
                                    <div className="col-md-6">
                                        <div className="card card-animate">
                                        <a className="" href="javascript:void(0)" onClick={() => { this.props.navigate('/admin/order-managment') }}>
                                                <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="fw-medium text-muted mb-0">Total Revenue</p>
                                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                                            <span className="counter-value" data-target="97.66" />₹{totalAmount?totalAmount.toFixed(2):""}
                                                        </h2>
                                                        {/* <p className="mb-0 text-muted">
                                                            <span className="badge bg-light text-danger mb-0">
                                                                <i className="ri-arrow-down-line align-middle" /> 3.96 %
                                                            </span>
                                                            vs. previous month
                                                        </p> */}
                                                    </div>
                                                    <div>
                                                        <div className="avatar-sm flex-shrink-0">
                                                            {/* <span className="avatar-title bg-light-red text-primary-red rounded-circle fs-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={32}
                                                                    height={32}
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M3 20v-1h18v1H3Zm1-2.77V12h2v5.23H4Zm4.654 0V7h2v10.23h-2Zm4.673 0V10h2v7.23h-2Zm4.673 0V4h2v13.23h-2Z"
                                                                    />
                                                                </svg>
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </a>
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>
                                    {/* end col*/}
                                </div>
                                <div className="row g-4 mt-3 mb-3">
                                    <div className="col-md-12">
                                        <h5>Quick Links</h5>
                                    </div>
                                </div>
                                <div className="row g-4">
                                {/* this.props.navigate('/admin/product-managment/list') */}
                                    <div className="col-md-4">
                                        <div className="card card-animate">
                                            <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('order_management') }}>
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="fw-medium text-link mb-0">Order Management</p>
                                                            <h2 className="mt-4 ff-secondary fw-semibold">
                                                                {/* <span className="counter-value" data-target="28.05" />100 */}
                                                            </h2>

                                                        </div>
                                                        <div>
                                                            <div className="avatar-sm flex-shrink-0">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></a>
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>
                                    {/* end col*/}
                                    <div className="col-md-4">
                                        <div className="card card-animate">
                                        <a className="" href="javascript:void(0)" onClick={() => { this.props.navigate('/admin/product-managment/list') }}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="fw-medium text-link mb-0">Product Management</p>
                                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                                            {/* <span className="counter-value" data-target="97.66" />150 */}
                                                        </h2>

                                                    </div>
                                                    <div>
                                                        <div className="avatar-sm flex-shrink-0">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div></a>
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>
                                    {/* end col*/}
                                    <div className="col-md-4">
                                        <div className="card card-animate">
                                        <a className="" href="javascript:void(0)" onClick={() => { this.navigateTo('callcenter') }}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="fw-medium text-link mb-0">Call Center Management</p>
                                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                                            {/* <span className="counter-value" data-target="97.66" />200 */}
                                                        </h2>

                                                    </div>
                                                    <div>
                                                        <div className="avatar-sm flex-shrink-0">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </a>
                                            {/* end card body */}
                                        </div>
                                        {/* end card*/}
                                    </div>

                                </div>
                                {/* Row end*/}
                            </div>
                        </div>
                        <AdminFooter />
                    </div>


                </div>






            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveMenuItem: (actveiveItem) => dispatch(AdminAction.setActiveMenuItem(actveiveItem))
    }
}

const mapStateToProps = ({ AdminReducer, CommonReducer }) => {
    const { admin } = AdminReducer
    const { summaryDetails } = CommonReducer
    return {
        admin,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(Dashboard)))