import React, { Component } from "react";
import $ from "jquery";
import moment from "moment";
import config from "../../config/emrok.config";
import { Lightbox } from "react-modal-image";

class CustomDatatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
      pagination: false,
      selectable: false,
      handleTrackOrderClick: undefined,
      handleEditPatientDetails: undefined,
      handleTrackOrder: undefined,
      downloadPdf: undefined,
      downloadQR: undefined,
      handleEditPatientDetails: undefined,
      handleCancelOrder: undefined,
      handleApproveOrder: undefined,
      pageName: null,
      initialized: false,
      open: false,
      selectedid: "",
      handleUploadPrescription: undefined,
      handleEditCallCenter: undefined,
      removeCallCenter: undefined,
      handleEditStockist: undefined,
      editProduct: undefined,
    };
  }

  closeLightbox = () => {
    // this.state.open = true;
    this.setState({ open: false });
  };
  openLightBox = (itemid) => {
    //  alert(itemid);
    this.setState({ selectedid: itemid });
    this.setState({ open: true });
    // this.state.open = true;
  };

  static getDerivedStateFromProps(props, state) {
    return {
      columns: props.columns || [],
      data: props.data || [],
      pagination: props.hasOwnProperty("pagination") ? props.pagination : false,
      selectable: props.hasOwnProperty("selectable") ? props.selectable : false,
      handleTrackOrderClick: props.handleTrackOrderClick
        ? props.handleTrackOrderClick
        : null,
      handleEditPatientDetails: props.handleEditPatientDetails
        ? props.handleEditPatientDetails
        : null,
      handleCancelOrder: props.handleCancelOrder
        ? props.handleCancelOrder
        : null,
      handleApproveOrder: props.handleApproveOrder
        ? props.handleApproveOrder
        : null,
      handleTrackOrder: props.handleTrackOrder ? props.handleTrackOrder : null,
      downloadPdf: props.downloadPdf ? props.downloadPdf : null,
      downloadQR: props.downloadQR ? props.downloadQR : null,
      handleEditCallCenter: props.handleEditCallCenter
        ? props.handleEditCallCenter
        : null,
      handleUploadPrescription: props.handleUploadPrescription
        ? props.handleUploadPrescription
        : null,
      removeCallCenter: props.removeCallCenter ? props.removeCallCenter : null,
      handleEditStockist: props.handleEditStockist
        ? props.handleEditStockist
        : null,
      pageName: props.pageName ? props.pageName : null,
      editProduct: props.editProduct || undefined,
    };
  }

  componentDidMount = () => {
    console.log(this.props.columns, "columns");
    console.log(this.props.data, "data");
    // Initialize the DataTable when the component mounts
    // if(this.state.data.length) {
    // $('#dt-tbl').DataTable().destroy();

    $("#dt-tbl").DataTable({
      searching: true, // Enable search functionality
      paging: true, // Enable pagination
      aaSorting: [],
      // columnDefs: [
      //     {
      //         type: 'date-dd-mm-yyyy', // Define a custom sorting type
      //         targets: [7], // Apply it to the first column (adjust the index)
      //     },
      // ],
      // order: [[7, 'desc']],
      scrollX: true,
      scrollCollapse: true,
      scrollY: "350px",
      fixedHeader: true,
      buttons: [
        {
          extend: "excel",
          text: "Export to Excel",
          filename: "datatable_export", // Set the filename
          exportOptions: {
            columns: ":visible", // Export only visible columns
          },
        },
      ],
      // sorting: true,
    });

    // $.fn.dataTable.Buttons.init($('#dt-tbl'));

    this.setState({ initialized: true });

    // }
    // Add the custom class to the dataTables_wrapper element
    $(".dataTables_wrapper").addClass("parent-data-table-wrap");
  };

  reInitialize = async () => {
    if (this.state.initialized) {
      //  $('#dt-tbl').DataTable().destroy();
      $("#dt-tbl").DataTable({
        searching: true, // Enable search functionality
        paging: true, // Enable pagination
        // sorting: true,
      });

      this.setState({ initialized: false });
    }
  };

  componentWillUnmount() {
    // Destroy the DataTable when the component unmounts
    // $(this.tableRef).DataTable().destroy();
    $("#dt-tbl").DataTable().destroy();
    // this.setState({ initialized: false });
  }

  render = () => {
    const { columns, data, pagination, selectable, pageName } = this.props;
    const { initialized, editProduct } = this.state;
    console.log(this.state, "dataall");
    // if (data.length && columns.length && this.state.initialized) {
    //  this.reInitialize()
    // }
    return (
      data.length &&
      columns.length && (
        <table id="dt-tbl" className={`display ${pageName == "productManagenment" && 'product-management-table-wrap'}`} style={{ width: "100%" }}>
          <thead>
            <tr>
              {columns.map((item, index) => (
                <th key={`col-${index}`}>{item.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pageName && pageName == "orderhistory"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>ID-{item.order_id}</td>
                  {/* <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td> */}
                  <td>
                    <p style={{ display: "none" }}>
                      {item.createdAt &&
                        moment(item.createdAt).format("YYYY-MM-DD")}
                    </p>
                    {item.createdAt &&
                      moment(item.createdAt).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    ₹
                    {item.total
                      ? parseFloat(item.total).toFixed(2)
                      : item.total}
                  </td>
                  <td>Address-{item.address}</td>
                  <td>
                    <button type="button" className="landing-pending-btn">
                      {item.orderStatus}
                    </button>
                  </td>
                  <td>
                    <div className="track-order-btn">
                      <a
                        href="javscript:void(0);"
                        onClick={this.state.handleTrackOrderClick}
                        name={item._id}
                      >
                        {" "}
                        Track Order{" "}
                      </a>
                    </div>
                  </td>
                </tr>
              ))
              : null}

            {pageName && pageName == "orderhistorytracking"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>ID-{item.order_id}</td>
                  <td>
                    <p style={{ display: "none" }}>
                      {item.createdAt &&
                        moment(item.createdAt).format("YYYY-MM-DD")}
                    </p>
                    {item.createdAt &&
                      moment(item.createdAt).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    ₹
                    {item.total
                      ? parseFloat(item.total).toFixed(2)
                      : item.total}
                  </td>
                  <td>Address-{item.address}</td>
                  <td>
                    <button type="button" className="landing-pending-btn">
                      {item.orderStatus}
                    </button>
                  </td>
                  <td>
                    <div className="track-order-btn">
                      <a
                        href="javscript:void(0);"
                        onClick={this.state.handleTrackOrderClick}
                        name={item._id}
                      >
                        {" "}
                        Track Order{" "}
                      </a>
                    </div>
                  </td>
                  <td>
                    {item.orderStatus == "rejected" ? (
                      <button
                        onClick={this.state.handleUploadPrescription}
                        name={item._id}
                        type="button"
                        className="landing-success-btn"
                      >
                        Upload Prescription
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))
              : null}

            {pageName && pageName == "mrlanding"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>{item.order_id}</td>
                  <td>{item.doctor_name}</td>
                  <td>{item.stockist_name}</td>
                  <td>{item.customer_state}</td>
                  <td>{item.customer_town}</td>
                  {/* <td>
                                <p>{item.customer_name}</p>
                                <p>Mob-{item.customer_mobile}</p>
                            </td> */}

                  <td>
                    <p style={{ display: "none" }}>
                      {item.order_date &&
                        moment(item.order_date).format("YYYY-MM-DD")}
                    </p>
                    {item.order_date &&
                      moment(item.order_date).format("DD-MM-YYYY")}
                  </td>
                  {/* <td><p style={{ display: 'none' }}>{item.grn_date && moment(item.grn_date).format('YYYY-MM-DD')}</p>{item.grn_date && moment(item.grn_date).format('DD-MM-YYYY')}</td> */}
                  <td>
                    <p style={{ display: "none" }}>
                      {item.delivery_date &&
                        moment(item.delivery_date).format("YYYY-MM-DD")}
                    </p>
                    {item.delivery_date &&
                      moment(item.delivery_date).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {item.total
                      ? parseFloat(item.total).toFixed(2)
                      : item.total}
                  </td>
                  <td>{item.product_code}</td>
                  <td>
                    {
                      <button
                        type="button"
                        className={
                          item.order_status != "complete"
                            ? "landing-pending-btn"
                            : "landing-success-btn"
                        }
                        onClick={this.state.handleTrackOrder}
                        name={item._id}
                      >
                        {item.order_status}
                      </button>
                    }
                  </td>
                </tr>
              ))
              : null}

            {pageName && pageName == "stockistlanding"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>{item.order_id}</td>
                  <td>{item.doctor_name}</td>
                  <td>{item.mr_name}</td>
                  <td>{item.customer_name}</td>
                  <td>{item.customer_state}</td>
                  <td>{item.customer_town}</td>
                  {/* <td>
                                <p>{item.customer_name}</p>
                                <p>Mob-{item.customer_mobile}</p>
                            </td> */}

                  <td>
                    <p style={{ display: "none" }}>
                      {item.order_date &&
                        moment(item.order_date).format("YYYY-MM-DD")}
                    </p>
                    {item.order_date &&
                      moment(item.order_date).format("DD-MM-YYYY")}
                  </td>
                  {/* <td><p style={{ display: 'none' }}>{item.grn_date && moment(item.grn_date).format('YYYY-MM-DD')}</p>{item.grn_date && moment(item.grn_date).format('DD-MM-YYYY')}</td> */}
                  <td>
                    <p style={{ display: "none" }}>
                      {item.delivery_date &&
                        moment(item.delivery_date).format("YYYY-MM-DD")}
                    </p>
                    {item.delivery_date &&
                      moment(item.delivery_date).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {item.total
                      ? parseFloat(item.total).toFixed(2)
                      : item.total}
                  </td>
                  <td>{item.product_code}</td>

                  <td>
                    {item.fileExtention != "" &&
                      item.fileExtention != "pdf" &&
                      this.state.open &&
                      this.state.selectedid == item._id && (
                        <Lightbox
                          medium={
                            config.serviceUrl +
                            "/images/" +
                            item.prescription_path
                          }
                          large={
                            config.serviceUrl +
                            "/images/" +
                            item.prescription_path
                          }
                          alt=""
                          onClose={this.closeLightbox}
                        />
                      )}
                    {item.fileExtention != "" &&
                      item.fileExtention != "pdf" &&
                      item.prescription_path ? (
                      <img
                        className="prescriptionimage"
                        src={
                          config.serviceUrl +
                          "/images/" +
                          item.prescription_path
                        }
                        onClick={() => this.openLightBox(item._id)}
                      ></img>
                    ) : (
                      ""
                    )}

                    {item.fileExtention != "" &&
                      item.fileExtention == "pdf" ? (
                      <a
                        className="pdffile"
                        href={
                          config.serviceUrl +
                          "/images/" +
                          item.prescription_path
                        }
                        target="_blank"
                      >
                        <i class="fa fa-file-pdf-o"></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {" "}
                    <a className="pdffile" target="_blank">
                      <i
                        class="fa fa-file-pdf-o"
                        onClick={this.state.downloadPdf}
                        id={item.order_id}
                      ></i>
                    </a>
                  </td>
                  <td>
                    {
                      <button
                        type="button"
                        className={
                          item.order_status != "complete"
                            ? "landing-pending-btn"
                            : "landing-success-btn"
                        }
                        onClick={this.state.handleTrackOrder}
                        name={item._id}
                      >
                        {item.order_status}
                      </button>
                    }
                  </td>
                  <td>
                    <div className="actionbtn">
                      {item.order_status == "pending" &&
                        item.is_prescription_approved != "yes" ? (
                        <button
                          type="button"
                          className="landing-success-btn bg-danger ms-3"
                          onClick={this.state.handleCancelOrder}
                          name={item._id}
                          id={item.customer_mobile}
                          data-bs-target="#orderCanceledModal"
                          data-bs-toggle="modal"
                        >
                          Reject
                        </button>
                      ) : (
                        ""
                      )}

                      {item.order_status == "pending" &&
                        item.is_prescription_approved != "yes" ? (
                        <button
                          type="button"
                          className="landing-success-btn bg-success ms-3"
                          onClick={this.state.handleApproveOrder}
                          name={item.order_id}
                          id={item.customer_mobile}
                        >
                          Approve
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))
              : null}

            {pageName && pageName == "callcenterlanding"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>{item.order_id}</td>
                  <td>
                    {" "}
                    <p>{item.customer_name}</p>
                  </td>

                  <td>{item.mr_name}</td>
                  <td>{item.doctor_name}</td>
                  <td>{item.stockist_name}</td>
                  <td>{item.customer_state}</td>
                  <td>{item.customer_city}</td>
                  <td>
                    <p style={{ display: "none" }}>
                      {item.order_date &&
                        moment(item.order_date).format("YYYY-MM-DD")}
                    </p>
                    {item.order_date &&
                      moment(item.order_date).format("DD-MM-YYYY")}
                  </td>
                  {/* <td>{item.order_date && item.order_date && moment(item.order_date).format('DD-MM-YYYY')}</td> */}
                  {/* <td>{item.order_date && moment(item.order_date).format('YYYY-MM-DD')}-{item.order_date && moment(item.order_date).format('DD-MM-YYYY')}</td> */}
                  <td>{item.product_code}</td>
                  <td>{item.total_product_qty}</td>
                  <td>
                    {item.total
                      ? parseFloat(item.total).toFixed(2)
                      : item.total}
                  </td>
                  <td>
                    {item.payment_type == "cod" ? "COD" : item.payment_status}
                  </td>
                  <td>{item.feedback}</td>
                  <td>
                    {item.fileExtention != "" &&
                      item.fileExtention != "pdf" &&
                      this.state.open &&
                      this.state.selectedid == item._id && (
                        <Lightbox
                          medium={
                            config.serviceUrl +
                            "/images/" +
                            item.prescription_path
                          }
                          large={
                            config.serviceUrl +
                            "/images/" +
                            item.prescription_path
                          }
                          alt=""
                          onClose={this.closeLightbox}
                        />
                      )}
                    {item.fileExtention != "" &&
                      item.fileExtention != "pdf" &&
                      item.prescription_path ? (
                      <img
                        className="prescriptionimage"
                        src={
                          config.serviceUrl +
                          "/images/" +
                          item.prescription_path
                        }
                        onClick={() => this.openLightBox(item._id)}
                      ></img>
                    ) : (
                      ""
                    )}

                    {item.fileExtention != "" &&
                      item.fileExtention == "pdf" ? (
                      <a
                        className="pdffile"
                        href={
                          config.serviceUrl +
                          "/images/" +
                          item.prescription_path
                        }
                        target="_blank"
                      >
                        <i class="fa fa-file-pdf-o"></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </td>

                  <td>
                    {
                      <button
                        type="button"
                        className={
                          item.order_status != "complete"
                            ? "landing-pending-btn"
                            : "landing-success-btn"
                        }
                        onClick={this.state.handleTrackOrder}
                        name={item._id}
                      >
                        {item.order_status}
                      </button>
                    }
                  </td>
                  <td>
                    <div className="d-flex">
                      {
                        <button
                          type="button"
                          className="landing-success-btn"
                          onClick={this.state.handleEditPatientDetails}
                          name={item.customer_id}
                        >
                          Edit
                        </button>
                      }
                      {item.order_status == "pending" ? (
                        <button
                          type="button"
                          className="landing-success-btn bg-danger ms-3"
                          onClick={this.state.handleCancelOrder}
                          name={item._id}
                          id={item.customer_mobile}
                          data-bs-target="#orderCanceledModal"
                          data-bs-toggle="modal"
                        >
                          Reject
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))
              : null}

            {pageName && pageName == "adminlanding"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>{item.order_id}</td>
                  <td>
                    {" "}
                    <p>{item.customer_name}</p>
                  </td>

                  <td>{item.mr_name}</td>
                  <td>{item.doctor_name}</td>
                  <td>{item.stockist_name}</td>
                  <td>{item.customer_state}</td>
                  <td>{item.customer_city}</td>
                  <td>
                    <p style={{ display: "none" }}>
                      {item.order_date &&
                        moment(item.order_date).format("YYYY-MM-DD")}
                    </p>
                    {item.order_date &&
                      moment(item.order_date).format("DD-MM-YYYY")}
                  </td>
                  {/* <td>{item.order_date && item.order_date && moment(item.order_date).format('DD-MM-YYYY')}</td> */}
                  {/* <td>{item.order_date && moment(item.order_date).format('YYYY-MM-DD')}-{item.order_date && moment(item.order_date).format('DD-MM-YYYY')}</td> */}
                  <td>{item.product_code}</td>
                  <td>{item.total_product_qty}</td>
                  <td>
                    {item.total
                      ? parseFloat(item.total).toFixed(2)
                      : item.total}
                  </td>
                  <td>
                    {item.payment_type == "cod" ? "COD" : item.payment_status}
                  </td>
                  <td>{item.feedback}</td>
                  <td>
                    {item.fileExtention != "" &&
                      item.fileExtention != "pdf" &&
                      this.state.open &&
                      this.state.selectedid == item._id && (
                        <Lightbox
                          medium={
                            config.serviceUrl +
                            "/images/" +
                            item.prescription_path
                          }
                          large={
                            config.serviceUrl +
                            "/images/" +
                            item.prescription_path
                          }
                          alt=""
                          onClose={this.closeLightbox}
                        />
                      )}
                    {item.fileExtention != "" &&
                      item.fileExtention != "pdf" &&
                      item.prescription_path ? (
                      <img
                        className="prescriptionimage"
                        src={
                          config.serviceUrl +
                          "/images/" +
                          item.prescription_path
                        }
                        onClick={() => this.openLightBox(item._id)}
                      ></img>
                    ) : (
                      ""
                    )}

                    {item.fileExtention != "" &&
                      item.fileExtention == "pdf" ? (
                      <a
                        className="pdffile"
                        href={
                          config.serviceUrl +
                          "/images/" +
                          item.prescription_path
                        }
                        target="_blank"
                      >
                        <i class="fa fa-file-pdf-o"></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {" "}
                    <a className="pdffile" target="_blank">
                      <i
                        class="fa fa-file-pdf-o"
                        onClick={this.state.downloadPdf}
                        id={item.order_id}
                      ></i>
                    </a>
                  </td>

                  <td>
                    {
                      <button
                        type="button"
                        className={
                          item.order_status != "complete"
                            ? "landing-pending-btn"
                            : "landing-success-btn"
                        }
                        onClick={this.state.handleTrackOrder}
                        name={item._id}
                      >
                        {item.order_status}
                      </button>
                    }
                  </td>
                  <td>
                    <div className="d-flex">
                      {
                        <button
                          type="button"
                          className="landing-success-btn"
                          onClick={this.state.handleEditPatientDetails}
                          name={item.customer_id}
                        >
                          Edit
                        </button>
                      }
                      {item.order_status == "pending" ? (
                        <button
                          type="button"
                          className="landing-success-btn bg-danger ms-3"
                          onClick={this.state.handleCancelOrder}
                          name={item._id}
                          id={item.customer_mobile}
                          data-cid={item.customer_id}
                          data-bs-target="#orderCanceledModal"
                          data-bs-toggle="modal"
                        >
                          Reject
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))
              : null}

            {pageName && pageName == "doctorlanding"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>{item.order_id}</td>
                  <td>
                    <p>{item.customer_name}</p>
                  </td>
                  <td>{item.product_code}</td>
                  <td>
                    <p style={{ display: "none" }}>
                      {item.order_date &&
                        moment(item.order_date).format("YYYY-MM-DD")}
                    </p>
                    {item.order_date &&
                      moment(item.order_date).format("DD-MM-YYYY")}
                  </td>

                  <td>
                    {
                      <button
                        type="button"
                        className={
                          item.order_status != "complete"
                            ? "landing-pending-btn"
                            : "landing-success-btn"
                        }
                        onClick={this.state.handleTrackOrder}
                        name={item._id}
                      >
                        {item.order_status}
                      </button>
                    }
                  </td>
                </tr>
              ))
              : null}

            {pageName && pageName == "qrcodegeneration"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>{item.doctor_name}</td>
                  <td>{item.doctor_code}</td>
                  <td>{item.state}</td>
                  <td>
                    {
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.state.downloadQR}
                        id={item._id}
                      >
                        Download QR Code
                      </button>
                    }
                  </td>
                </tr>
              ))
              : null}

            {pageName && pageName == "callcentermanagment"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.mobileno}</td>
                  <td>
                    <div className="d-flex">
                      {
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.state.handleEditCallCenter}
                          id={item._id}
                        >
                          Edit
                        </button>
                      }
                      {
                        <button
                          type="button"
                          className="btn btn-primary ms-3"
                          onClick={this.state.removeCallCenter}
                          id={item._id}
                        >
                          Remove
                        </button>
                      }
                    </div>
                  </td>
                </tr>
              ))
              : null}

            {pageName && pageName == "stockistmanagment"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>{item.name}</td>
                  <td>{item.plant_id}</td>
                  <td>{item.email}</td>
                  <td>{item.mobileno}</td>
                  <td>
                    <div className="d-flex">
                      {
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.state.handleEditStockist}
                          id={item.plant_id}
                        >
                          Edit
                        </button>
                      }
                      {/* {<button type="button" className="btn btn-primary ms-3" onClick={this.state.removeCallCenter} id={item._id}>
                                        Remove</button>} */}
                    </div>
                  </td>
                </tr>
              ))
              : null}
            {pageName && pageName == "productManagenment"
              ? data.map((item, index) => (
                <tr key={`body-${index}`}>
                  <td>{item.name}</td>
                  <td>{item._id}</td>
                  <td>
                    {
                      <img
                        className="prescriptionimage"
                        src={`${config.serviceUrl}/images/${item.poduct_img !== "" ? item.poduct_img : "product_img/no-image.png"}?time=${(new Date()).getTime()}`}
                        // src={`${config.serviceUrl}/images/product_img/${item.poduct_img !== "" ? item._id : "no-image"}.png?time=${(new Date()).getTime()}`}
                      ></img>
                    }
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      {
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            editProduct ? editProduct(item) : null
                          }
                          id={item.material}
                        >
                          Edit
                        </button>
                      }
                    </div>
                  </td>
                </tr>
              ))
              : null}
          </tbody>
        </table>
      )
    );
  };
}

export default CustomDatatable;
