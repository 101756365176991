import {
  SET_PRODUCTS,
  CLEAR_PRODUCT_STATE,
  SET_ALL_PRODUCTS_BY_MATEERIAL,
  SET_EDITABLE_PRODUCT
} from '../types/Product.type'
class ProductAction {
  setProducts = (data) => {
    return { type: SET_PRODUCTS, payload: data }
  }
  setProductsByMaterial = (data) => {
    return { type: SET_ALL_PRODUCTS_BY_MATEERIAL, payload: data }
  }
  setEditableProduct = (data) => {
    return { type: SET_EDITABLE_PRODUCT, payload: data }
  }
  clearState = () => {
    return { type: CLEAR_PRODUCT_STATE, payload: null }
  }
}
export default new ProductAction()